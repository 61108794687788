angular
    .module('annexaApp')
    .component('annexaBoxRightAccess',{
        templateUrl: './components/tram/annexa-box-right-access/annexa-box-right-access.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', 'TramFactory', 'CustomFieldFactory', 
        function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope, TramFactory, CustomFieldFactory) {
            var vm = this;
        
            this.$onInit = function () {
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.archive.accessRights';
            	}
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            new: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            emptyText: '@'
        }
    })
    .component('annexaBoxRightAccessRender', {
        templateUrl: './components/tram/annexa-box-right-access/annexa-box-right-access-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'TramFactory', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'AnnexaPermissionsFactory', 
        function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, TramFactory,GlobalDataFactory, globalModals, AnnexaModalFactory, AnnexaPermissionsFactory) {
        	var vm = this;
			vm.isEditRightAccess = false;
        	vm.languageColumn = Language.getActiveColumn();
			vm.rightAccessStatuses = [
				{id: 'REQUEST', name: $filter('translate')('global.literals.rightAccessStatus.REQUEST'), class: 'text-warn', icon: 'circle', style: 'text-warn'},
	            {id: 'ACCEPTED', name: $filter('translate')('global.literals.rightAccessStatus.ACCEPTED'), class: 'text-success', icon: 'circle', style: 'text-success'},
	            {id: 'DENIED', name: $filter('translate')('global.literals.rightAccessStatus.DENIED'), class: 'text-danger', icon: 'circle', style: 'text-danger'},
	            {id: 'REVOKED', name: $filter('translate')('global.literals.rightAccessStatus.REVOKED'), class: 'text-grey', icon: 'circle', style: 'text-grey'},
	            {id: 'EXPIRED', name: $filter('translate')('global.literals.rightAccessStatus.EXPIRED'), class: 'text-blue', icon: 'circle', style: 'text-blue'}
			];

			vm.getIconClassStatus = function(item){
			    switch (item.rightAccessStatus) {
                    case 'REQUEST':
                        return 'text-warn';
                    case 'ACCEPTED':
                        return 'text-success';
                    case 'DENIED':
                        return 'text-danger';
                    case 'REVOKED':
                        return 'text-grey';
                    case 'EXPIRED':
                        return 'text-blue';
                }

                return '';
            }
			
        	vm.viewRightAccess = function(item){
				TramFactory.viewRightAccess(item, $state);
			}
			
			vm.accept = function(item){
				DialogsFactory.confirm('global.literals.rightAccess', 'global.literals.acceptRightAccessConfirm').then(function (data) {
    		    	TramFactory.acceptRightAccess(item.id).then(function(data) {
						if(data && data.id){
							var date = new Date();
							item.rightAccessStatus = data.rightAccessStatus;
							item.finished = ((data.endDate < date)?true:false); 
							item.current = ((data.startDate <= date && data.endDate >= date)?true:false);
							item.canModifyStatus =  (((data.rightAccessStatus == 'REQUEST' && !data.finished) || (data.rightAccessStatus == 'ACCEPTED' && !data.current && !data.finished) || (data.rightAccessStatus == 'ACCEPTED' && data.current))?true:false);
						}
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.literals.acceptRightAccessError'));
                    });
               }).catch(function (data) {
    	       });
			}
			
			vm.deny = function(item){
				DialogsFactory.confirm('global.literals.rightAccess', 'global.literals.denyRightAccessConfirm').then(function (data) {
    		    	TramFactory.denyRightAccess(item.id).then(function(data) {
						if(data && data.id){
							var date = new Date();
							item.rightAccessStatus = data.rightAccessStatus;
							item.finished = ((data.endDate < date)?true:false); 
							item.current = ((data.startDate <= date && data.endDate >= date)?true:false);
							item.canModifyStatus =  (((data.rightAccessStatus == 'REQUEST' && !data.finished) || (data.rightAccessStatus == 'ACCEPTED' && !data.current && !data.finished) || (data.rightAccessStatus == 'ACCEPTED' && data.current))?true:false);
						}
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.literals.denyRightAccessError'));
                    });
               }).catch(function (data) {
    	       });
			}
			
			vm.delete = function(item){
				DialogsFactory.confirm('global.literals.rightAccess', 'global.literals.deleteRightAccessConfirm').then(function (data) {
    		    	TramFactory.deleteRightAccess(item.id).then(function(data) {
						if(data){
							var index = $linq(vm.content).indexOf("x => x.id == "+item.id );
	    					if(index >= 0){
	    						vm.content.splice(index, 1);
	    					}
						}
	                }).catch(function (error) {
	                	DialogsFactory.error($filter('translate')('global.literals.deleteRightAccessError'));
	                });
               }).catch(function (data) {
    	       });
			}
						
			vm.revoke = function(item){
				DialogsFactory.confirm('global.literals.rightAccess', 'global.literals.revokeRightAccessConfirm').then(function (data) {
    		    	TramFactory.revokeRightAccess(item.id).then(function(data) {
						if(data && data.id){
							var date = new Date();
							item.rightAccessStatus = data.rightAccessStatus;
							item.finished = ((data.endDate < date)?true:false); 
							item.current = ((data.startDate <= date && data.endDate >= date)?true:false);
							item.canModifyStatus =  (((data.rightAccessStatus == 'REQUEST' && !data.finished) || (data.rightAccessStatus == 'ACCEPTED' && !data.current && !data.finished) || (data.rightAccessStatus == 'ACCEPTED' && data.current))?true:false);
						}
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.literals.revokeRightAccessError'));
                    });
               }).catch(function (data) {
    	       });
			}
			
			this.$onInit = function () {
            	if(vm.isEdit && AnnexaPermissionsFactory.havePermission(['admin_access_rights'])){
					vm.isEditRightAccess = true;
				}
				if(vm.isEditRightAccess && vm.content && vm.content.length > 0){
					var date = new Date();
					_.forEach(vm.content, function(ra){
						ra.finished = ((ra.endDate < date)?true:false); 
						ra.current = ((ra.startDate <= date && ra.endDate >= date)?true:false);
						ra.canModifyStatus =  (((ra.rightAccessStatus == 'REQUEST' && !ra.finished) || (ra.rightAccessStatus == 'ACCEPTED' && !ra.current && !ra.finished) || (ra.rightAccessStatus == 'ACCEPTED' && ra.current))?true:false);
					});
				} 
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            origin: '@'
        }
    })
	.component('annexaRightAccessRemainingDays',{
        templateUrl: './components/tram/annexa-box-right-access/annexa-right-access-remaining-days.html',
        controller:['$rootScope', 'HelperService', '$filter', '$scope', 'TramFactory', function($rootScope, HelperService, $filter, $scope, TramFactory) {
            var vm = this;
        	vm.label = '';
			vm.labelClass = '';
            this.$onInit = function () {
            	TramFactory.getRightAccessRemainingDays(vm.dossierId).then(function(data) {
					vm.label = ((data > 5)?'+'+data:''+data);
					vm.labelClass = HelperService.getWorkingDaysClass(data);	
                }).catch(function (error) {
                });
            }
        }],
        bindings: {
            dossierId: '='
        }
    });